/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
/* eslint-disable guard-for-in */
// @ts-nocheck
import React, { Suspense, useState, useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import {
  Box,
  Menu,
  MenuItem,
  IconButton,
  Grid,
  List,
  Typography,
  Toolbar,
  AppBar,
  Drawer,
  Fab,
  Divider,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useSelector } from 'react-redux';
import ViewProfileForm from '../components/Layout/ViewProfileForm';
import ChangePasswordForm from '../components/Layout/ChangePasswordForm';
import PageLevelLoader from '../components/Common/PageLevelLoader';
import ScrollTop from '../components/Layout/ScrollTop';
import { Assets } from '../assets/index';
import SidebarMenu from '../components/SideBarMenu';
import { ROUTES } from '../routing/routes';
import PageNotFound from './PageNotFound';
import { Hooks } from '../hooks/index';

const PREFIX = 'Layout';

const classes = {
  appBar: `${PREFIX}-appBar`,
  menuNormal: `${PREFIX}-menuNormal`,
  menuSelected: `${PREFIX}-menuSelected`,
  subMenu: `${PREFIX}-subMenu`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.appBar}`]: {
    zIndex: theme.zIndex.drawer + 1,
  },

  [`& .${classes.menuNormal}`]: {
    cursor: 'pointer',
    color: theme.palette.common.blue,
    '&:hover': {
      background: theme.palette.common.lightergreen,
    },
  },

  [`& .${classes.menuSelected}`]: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.lightergreen,
    cursor: 'pointer',
  },
  [`& .${classes.subMenu}`]: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}));

const drawerWidth = 230;

/**
 * @function Layout
 * @desciption a UI component that renders the layout
 * @param props
 * @returns {JSX} A UI component to render the Layout
 * @example <Layout />
 */
export default function Layout(props) {
  const {
    logout,
    changePassword,
    keycloakUserLogout,
    // setRefreshTokenInterval,
    // refreshTokenIntervalObj,
  } = Hooks.useAuth();
  const { getUserData, userData, isLoading, updateProfile } = Hooks.useUser();
  const panelNavigation = useSelector((state) => state.app.panelNavigation);
  const { fetchTeamData, fetchAnalystData, analystList, teamList } =
    Hooks.useCommon();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [profileOpen, setProfileOpen] = useState(false);
  const [passwordFrom, setPasswordFrom] = useState(false);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  /**
   * @function handleClose
   * @descripton a function that is called for menu options
   * @param {*} menu
   * @return{void}
   */
  const handleClose = (menu) => {
    switch (menu) {
      case 'keycloakUserLogout':
        keycloakUserLogout();
        break;
      case 'logout':
        logout();
        break;
      case 'profile':
        break;
      default:
    }
    setAnchorEl(null);
  };
  useEffect(() => {
    fetchTeamData();
    fetchAnalystData();
    getUserData();
  }, [open]);
  const profileDialogOpen = () => () => {
    handleClose('profile');
    if (userData !== undefined) {
      setProfileOpen(true);
    }
  };
  const handleClickOpen = () => {
    handleClose('profile');
    setPasswordFrom(true);
  };
  const handlePasswordClose = () => {
    setPasswordFrom(false);
  };

  const SubmitProfileForm = () => {
    setProfileOpen(false);
  };

  const handleProfileClose = () => {
    setProfileOpen(false);
  };
  // drawer for sidebar
  const drawer = (
    <div>
      <Toolbar>
        <Box flexGrow="1">
          <img
            src={Assets.SAMSLogo}
            alt=""
            style={{ height: 35, width: 110 }}
          />
        </Box>
      </Toolbar>
      <Divider />
      <List sx={{ minWidth: 230 }}>
        <SidebarMenu
          panelNavigation={
            panelNavigation === undefined || null ? {} : panelNavigation
          }
          history={history}
          classes={classes}
        />
      </List>
    </div>
  );
  const container =
    window !== undefined ? () => window().document.body : undefined;
  function extractRoutes(obj) {
    const routes = [];

    for (const key in obj) {
      if (typeof obj[key] === 'object' && obj[key].hasOwnProperty('route')) {
        routes.push(obj[key].route);
      }

      if (typeof obj[key] === 'object' && obj[key].hasOwnProperty('children')) {
        routes.push(...extractRoutes(obj[key].children));
      }
    }

    return routes;
  }
  // const panelNavigation = localStorage.getItem('panelNavigation');
  const allowedRoutes = extractRoutes(panelNavigation);

  return (
    <StyledGrid sx={{ display: 'flex' }}>
      <AppBar position="fixed" color="default" elevation={1}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
            size="large"
          >
            <MenuIcon fontSize="large" />
          </IconButton>
          <Box flexGrow="1">
            <img
              src={Assets.SAMSLogo}
              alt=""
              style={{ height: 35, width: 110 }}
            />
          </Box>
          <Box display="flex">
            <Box>
              <Box>
                <Typography variant="subtitle1">
                  {userData?.first_name} {userData?.last_name}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="caption"
                  sx={{
                    textTransform: 'capitalize',
                  }}
                >
                  {['maker', 'checker']?.includes(userData?.user_type)
                    ? 'Analyst'
                    : userData?.user_type}
                </Typography>
              </Box>
            </Box>
            <Box>
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                onClick={handleMenu}
                size="large"
              >
                <AccountCircle style={{ fontSize: 30 }} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={profileDialogOpen()}>View Profile</MenuItem>
                <MenuItem onClick={handleClickOpen}>Change Password</MenuItem>
                <MenuItem
                  onClick={() => {
                    if (process.env.REACT_APP_KEYCLOAK_LOGIN === 'true') {
                      handleClose('keycloakUserLogout');
                    } else {
                      handleClose('logout');
                    }
                  }}
                >
                  Logout
                </MenuItem>
              </Menu>
            </Box>
            <Box>
              <ViewProfileForm
                profileOpen={profileOpen}
                SubmitProfileForm={SubmitProfileForm}
                handleProfileClose={handleProfileClose}
                userData={userData}
                isLoading={isLoading}
                updateProfile={updateProfile}
              />
              <ChangePasswordForm
                passwordFrom={passwordFrom}
                handlePasswordClose={handlePasswordClose}
                changePassword={changePassword}
                logout={logout}
              />
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={(theme) => ({
          flexGrow: 1,
          overflow: 'auto',
          padding: theme.spacing(4, 8),
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        })}
      >
        <Toolbar id="back-to-top-anchor" />
        <PageLevelLoader />
        <Suspense fallback="">
          <Switch>
            {ROUTES.map((route, idx) => {
              return route.component ? (
                <Route
                  key={idx.route}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  // eslint-disable-next-line no-shadow
                  render={(props) =>
                    allowedRoutes.includes(route.path) ? (
                      <route.component
                        {...props}
                        userData={userData}
                        analystList={analystList}
                        getUserData={getUserData}
                        teamList={teamList}
                      />
                    ) : (
                      <PageNotFound {...props} />
                    )
                  }
                />
              ) : null;
            })}
            {panelNavigation?.['manager-dashboard']?.route ===
            '/manager-dashboard' ? (
              <Redirect exact path="/" to="/manager-dashboard" />
            ) : panelNavigation?.['admin-dashboard']?.route ===
              '/admin-dashboard' ? (
              <Redirect exact path="/" to="/admin-dashboard" />
            ) : (
              <Redirect exact path="/" to="/analyst-dashboard" />
            )}
            <Route
              path="*"
              name="Not Found"
              // eslint-disable-next-line no-shadow
              render={(props) => <PageNotFound {...props} />}
            />
          </Switch>
        </Suspense>
      </Box>
      <ScrollTop {...props}>
        <Fab color="primary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </StyledGrid>
  );
}
Layout.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func.isRequired,
};
